@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfecg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
* {
  font-family: Poppins;
}

body .iks-layout-menu.p-menu {
  background-color: transparent;
  border-color: transparent;
}
body .iks-layout-menu.p-menu .p-menuitem-link {
  margin-bottom: 10px;
}
body .iks-layout-menu.p-menu .p-menuitem-link-active .p-menuitem-icon, body .iks-layout-menu.p-menu .p-menuitem-link-active .p-menuitem-text {
  color: #6F3DF1;
  font-weight: 700;
}
body .iks-layout-menu.p-menu .p-menuitem-link:hover {
  background-color: transparent;
}
body .iks-layout-menu.p-menu .p-menuitem-link:hover .p-menuitem-icon, body .iks-layout-menu.p-menu .p-menuitem-link:hover .p-menuitem-text {
  color: #6F3DF1;
  font-weight: 700;
}
body .iks-layout-menu.p-menu .p-menuitem-link:focus {
  box-shadow: none;
}
body .iks-layout-menu.p-menu .p-menuitem-icon, body .iks-layout-menu.p-menu .p-menuitem-text {
  color: black;
  font-weight: 400;
}

.p-menu {
  width: 100%;
}

.primary-color {
  color: #393D8A;
}

.secondary-color {
  color: #6F3DF1;
}

.tertiary-color {
  color: #73EFC9;
}

/* Quitar fondo de autorellenado */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

/* Estilos para input */
.iksinput {
  margin-top: 15px;
}
.iksinput__input {
  background-color: #F2F2F2 !important;
  border-radius: 9px 9px 9px 9px !important;
  box-shadow: none !important;
  border: 1px solid #828282 !important;
  padding: 10px 15px !important;
  color: #393D8A !important;
}
.iksinput__inputactive {
  border: 2px solid #6F3DF1 !important;
}
.iksinput label {
  position: relative;
  display: inline-block;
  border-radius: 0;
  font-size: 10px;
}
.iksinput label span {
  position: absolute;
  left: 13px;
  top: -7px;
  cursor: text;
  color: #6F3DF1;
  background: linear-gradient(180deg, white 0%, #E0E0E0 90%);
  padding: 1px 5px;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
}
.iksinput label.empty span {
  display: none;
}

/* Estilos para input con icon */
.iksinput-icon {
  margin-top: 30px;
}
.iksinput-icon__input {
  background-color: white !important;
  border-radius: 0px 9px 9px 0px !important;
  box-shadow: none !important;
  border: 1px solid #828282 !important;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #393D8A !important;
}
.iksinput-icon__inputpass {
  background-color: white !important;
  border-radius: 0px 0px 0px 0px !important;
  box-shadow: none !important;
  border: 1px solid #828282 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.iksinput-icon__pi {
  background-color: white !important;
  border-bottom-left-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-color: #828282;
}
.iksinput-icon__pipass {
  background-color: white !important;
  border-bottom-right-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-color: #828282;
}
.iksinput-icon__inputactive {
  border: 2px solid #6F3DF1 !important;
}
.iksinput-icon__piactive {
  border: 2px solid #6F3DF1 !important;
}
.iksinput-icon__inputleft {
  border-left-width: 0px !important;
}
.iksinput-icon__inputright {
  border-right-width: 0px !important;
}
.iksinput-icon__inputhorizontal {
  border-right-width: 0px !important;
  border-left-width: 0px !important;
}
.iksinput-icon label {
  position: relative;
  display: inline-block;
  border-radius: 0;
  font-size: 10px;
}
.iksinput-icon label span {
  position: absolute;
  left: 0px;
  top: -7px;
  cursor: text;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  color: #6F3DF1;
  background-color: white;
  padding: 1px 5px;
}
.iksinput-icon label.empty span {
  display: none;
}

/* button violeta */
.p-button.button_itx_secondary,
.p-buttonset.button_itx_secondary > .p-button,
.p-splitbutton.button_itx_secondary > .p-button {
  color: #ffffff;
  background: #6F3DF1;
  border: 1px solid #6F3DF1;
}

.p-button.button_itx_secondary:enabled:hover,
.p-buttonset.button_itx_secondary > .p-button:enabled:hover,
.p-splitbutton.button_itx_secondary > .p-button:enabled:hover {
  background: rgba(111, 61, 241, 0.5);
  color: #ffffff;
  border-color: rgba(111, 61, 241, 0.5);
}

.p-button.button_itx_secondary:enabled:focus,
.p-buttonset.button_itx_secondary > .p-button:enabled:focus,
.p-splitbutton.button_itx_secondary > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #b0bec5;
}

.p-button.button_itx_secondary:enabled:active,
.p-buttonset.button_itx_secondary > .p-button:enabled:active,
.p-splitbutton.button_itx_secondary > .p-button:enabled:active {
  background: rgba(111, 61, 241, 0.5);
  color: #ffffff;
  border-color: rgba(111, 61, 241, 0.5);
}

.p-button.button_itx_secondary.p-button-outlined,
.p-buttonset.button_itx_secondary > .p-button.p-button-outlined,
.p-splitbutton.button_itx_secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #6F3DF1;
  border: 1px solid;
}

.p-button.button_itx_secondary.p-button-outlined:enabled:hover,
.p-buttonset.button_itx_secondary > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.button_itx_secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(96, 125, 139, 0.04);
  color: rgba(111, 61, 241, 0.5);
  border: 1px solid;
}

.p-button.button_itx_secondary.p-button-outlined:enabled:active,
.p-buttonset.button_itx_secondary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.button_itx_secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(96, 125, 139, 0.16);
  color: rgba(111, 61, 241, 0.5);
  border: 1px solid;
}

.p-button.button_itx_secondary.p-button-text,
.p-buttonset.button_itx_secondary > .p-button.p-button-text,
.p-splitbutton.button_itx_secondary > .p-button.p-button-text {
  background-color: transparent;
  color: #6F3DF1;
  border-color: transparent;
}

.p-button.button_itx_secondary.p-button-text:enabled:hover,
.p-buttonset.button_itx_secondary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.button_itx_secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(96, 125, 139, 0.04);
  border-color: transparent;
  color: rgba(111, 61, 241, 0.5);
}

.p-button.button_itx_secondary.p-button-text:enabled:active,
.p-buttonset.button_itx_secondary > .p-button.p-button-text:enabled:active,
.p-splitbutton.button_itx_secondary > .p-button.p-button-text:enabled:active {
  background: rgba(96, 125, 139, 0.16);
  border-color: transparent;
  color: rgba(111, 61, 241, 0.5);
}

/* button verde */
.p-button.button_itx_tertiary,
.p-buttonset.button_itx_tertiary > .p-button,
.p-splitbutton.button_itx_tertiary > .p-button {
  color: #6F3DF1;
  background: #73EFC9;
  border: 1px solid #73EFC9;
}

.p-button.button_itx_tertiary:enabled:hover,
.p-buttonset.button_itx_tertiary > .p-button:enabled:hover,
.p-splitbutton.button_itx_tertiary > .p-button:enabled:hover {
  background: rgba(115, 239, 201, 0.5);
  color: #6F3DF1;
  border-color: rgba(115, 239, 201, 0.5);
}

.p-button.button_itx_tertiary:enabled:focus,
.p-buttonset.button_itx_tertiary > .p-button:enabled:focus,
.p-splitbutton.button_itx_tertiary > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #b0bec5;
}

.p-button.button_itx_tertiary:enabled:active,
.p-buttonset.button_itx_tertiary > .p-button:enabled:active,
.p-splitbutton.button_itx_tertiary > .p-button:enabled:active {
  background: rgba(115, 239, 201, 0.5);
  color: #6F3DF1;
  border-color: rgba(115, 239, 201, 0.5);
}

.p-button.button_itx_tertiary.p-button-outlined,
.p-buttonset.button_itx_tertiary > .p-button.p-button-outlined,
.p-splitbutton.button_itx_tertiary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #73EFC9;
  border: 1px solid;
}

.p-button.button_itx_tertiary.p-button-outlined:enabled:hover,
.p-buttonset.button_itx_tertiary > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.button_itx_tertiary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(96, 125, 139, 0.04);
  color: rgba(115, 239, 201, 0.5);
  border: 1px solid;
}

.p-button.button_itx_tertiary.p-button-outlined:enabled:active,
.p-buttonset.button_itx_tertiary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.button_itx_tertiary > .p-button.p-button-outlined:enabled:active {
  background: rgba(96, 125, 139, 0.16);
  color: rgba(115, 239, 201, 0.5);
  border: 1px solid;
}

.p-button.button_itx_tertiary.p-button-text,
.p-buttonset.button_itx_tertiary > .p-button.p-button-text,
.p-splitbutton.button_itx_tertiary > .p-button.p-button-text {
  background-color: transparent;
  color: #73EFC9;
  border-color: transparent;
}

.p-button.button_itx_tertiary.p-button-text:enabled:hover,
.p-buttonset.button_itx_tertiary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.button_itx_tertiary > .p-button.p-button-text:enabled:hover {
  background: rgba(96, 125, 139, 0.04);
  border-color: transparent;
  color: rgba(115, 239, 201, 0.5);
}

.p-button.button_itx_tertiary.p-button-text:enabled:active,
.p-buttonset.button_itx_tertiary > .p-button.p-button-text:enabled:active,
.p-splitbutton.button_itx_tertiary > .p-button.p-button-text:enabled:active {
  background: rgba(96, 125, 139, 0.16);
  border-color: transparent;
  color: rgba(115, 239, 201, 0.5);
}

/* Cabezera publica */
.iks-header-public {
  width: 100%;
}
.iks-header-public img {
  width: 200px;
  cursor: pointer;
}

p-inputNumber.input-code .p-inputnumber-input {
  width: 55px;
  height: 55px;
  text-align: center;
  background-color: white;
  font-size: 18px;
  font-weight: 700;
  color: #393D8A;
  border-color: #6F3DF1;
  border-radius: 15px;
}

body p-checkbox .p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  background: #393D8A;
  color: transparent;
  border-radius: 50%;
  font-size: 14px;
}
body p-checkbox .p-checkbox .p-checkbox-box.p-highlight {
  background: transparent;
}

.accept-button-dialog {
  background-color: #6F3DF1 !important;
}