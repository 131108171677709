// Theme Colors
$itx-primary-color: #393D8A;  // Al cambiarlo, reemplazar en manifest.webmanifest e index.html
$itx-secondary-color:  #6F3DF1;
$itx-tertiary-color: #73EFC9;
$itx-gray-3-color: #828282;
$itx-gray-6-color: #E0E0E0;
$itx-azul-cuerpo-color: #393D8A;

$itx-iksinput-horizontal: 15px;
$itx-iksinput-vertical: 10px;
